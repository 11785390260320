$primary: #0f446c;
$secondary: #d55b00;
$menu-border: #f2f4fa;
$drawer-item-color: #ffff;
$drawer-item-bg: $secondary;
$drawer-bg: $primary;
$drawer-text: #fffc;
$webkit-filter: grayscale(100%);
$filter: grayscale(100%);

@import "./common-files.scss";
