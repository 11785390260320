.k-tabstrip>.k-content {
  padding-right: 14px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* Reason */
.Summary {
  justify-content: flex-end;
  padding: 7px;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: inherit;
}

/* Word Break */
.Word {
  word-wrap: break-word !important;
}

.margin_top {
  margin-top: 28px !important;
}

/* Date Ranges */
.date-small {
  .dateRange {
    width: 174px !important;
  }

  .k-daterange-picker .k-dateinput,
  .k-daterangepicker .k-dateinput {
    width: 100% !important;
  }
}

.date-medium {
  .dateRange {
    width: 174px !important;
  }

  .k-daterange-picker .k-dateinput,
  .k-daterangepicker .k-dateinput {
    width: 100px !important;
  }
}

.date-large {
  .dateRange {
    width: 174px !important;
  }

  .k-daterange-picker .k-dateinput,
  .k-daterangepicker .k-dateinput {
    width: 100% !important;
  }
}

// Floating label width
.k-floating-label-container>.k-label {
  max-width: 163% !important;
  font-size: 14px !important;
  line-height: 1.4285714286;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  // top: calc(calc(1.4285714286 * 14px !important) + 1px + 4px);
  left: 9px;
  overflow: hidden;
  cursor: text;
  transform-origin: left center;
  transition: transform .2s ease-out, color .2s ease-out, top .2s ease-out, left .2s ease-out;
}

/* Search Icon Button */
.button {
  height: 29px;
  width: 29px;
}

.upload-imagebox {
  width: 200px;
  height: 200px;
  // background-color: #f5f5f5;

  img {
    width: 100% !important;
    object-fit: fill !important;
    height: 200px !important;
    border: 1px solid #ccc !important;
    border-radius: 5% !important;
  }
}

.password-eye-icon {
  width: 100% !important;

  .icon {
    color: black;
    float: right !important;
    position: relative;
    margin-top: -21px;
    margin-right: 10px;
  }
}


.icon-color {
  color: black;
}

/* Filter */
.k-i-filter-clear {
  display: none !important;
}

.k-filtercell-operator {
  display: none !important;
}

/*Dialog Content */
.dialog-content {
  margin: 30px !important;
  text-align: center !important;
  font-size: 14px !important;
}

.k-tilelayout {
  background-color: #fafafa !important;
}

.k-stepper .k-step-label .k-step-text {
  max-width: calc(13em - (16px + 4px));
  flex-grow: 1;
  white-space: pre-wrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.k-step-list-vertical .k-step {
  margin-bottom: 10px !important;
}

.k-form-buttons {
  margin-top: 0em !important;
  padding: 0;
  overflow: visible;
}

.k-form-error,
.k-form-field-error,
.k-floating-label-container.k-invalid>.k-label {
  color: #b01030 !important;
}

$border-radius: 2px;
$drawer-scrollbar-width: 0px;
$light: #f9f9f9;
$appbar-light-text: $secondary !important;

$appbar-bg: #ffff !important;
$appbar-text: #5a5a5a !important;

$info: #3e80ed;
$success: #358856;
$warning: #fdce3e;
$lock-warning: #fd9f3e !important;
$chip-filled-warning-text: #fc8610 !important;
$error: #b01030;
$body-text: #424242;
$body-bg: #ffffff;
$headings-text: #272727;
$subtle-text: #666666;
$disabled-text: #8f8f8f;
$component-text: #424242;
$component-bg: #ffffff;
$base-text: #424242;
$base-bg: darken($secondary, 5%);
$hovered-text: #424242;
$hovered-bg: #ebebeb;
$selected-bg: #dbdbdb;
$button-text: #424242;
$button-bg: #f5f5f5;
$link-text: $primary;
$link-hover-text: #d6534a;
$series-a: #ff6358;
$series-b: #ffd246;
$series-c: #78d237;
$series-d: #28b4c8;
$series-e: #2d73f5;
$series-f: #aa46be;

$header-bg: #ffff;
$header-text: $primary;

$grid-header-bg: #f8f8f8;
$grid-selected-bg: $selected-bg;
$grid-header-text: $primary !important;
$grid-header-text-weight: 700;
$grid-hovered-bg: #ebebeb;
$grid-bg: #f4f4f4;
$grid-sticky-bg: #f4f4f4;
$grid-alt-bg: #e7e7e7;
$grid-sticky-alt-bg: #e7e7e7;
$grid-sticky-border: 1px !default;
$grid-sticky-hovered-bg: #ebebeb;

$card-header-bg: $grid-header-bg;
$card-header-bg-hover: #ebebeb !important;
$card-header-text: $grid-header-text !important;

$custom-card-bg: #fff;
$custom-card-bg-hover: #ebebeb;

$listview-bg: #ffffff00 !important;

$card-bg: $custom-card-bg;
$card-body-padding-y: 0px 16px 16px;

$title-bar-bg: #ffffff;
$title-bar-text: #5a5a5a;

$sys-app-bg: #f56147;
$success-bg: $success;
$error-bg: $error;

$toolbar-bg: #ffff !important;

$grid-sorting-indicator-text: #5a5a5a !important;

$expander-header-bg: #f8f8f8 !important;

/* Grid Status Color*/
.expired {
  color: $error !important;
}

.alert {
  color: #d55b00 !important;
}

.normal {
  color: #424242 !important;
}

.k-expander-header {
  padding: 5px 15px !important;
}

.k-expander,
.k-state-focus {
  box-shadow: none !important;
}

.k-expander-sub-title {
  opacity: 1 !important;
  font-size: 12px !important;
  color: #464646 !important;
}

.k-expander-content {
  padding: 3px 15px !important;
}

.k-notification {
  height: auto !important;
  padding-top: 8px !important;
}

.k-notification-group {
  z-index: 1002 !important;
}

.w-full {
  width: 100% !important;
}

html,
body {
  height: 100%;
}

body {
  font-family: Segoe UI !important;
  margin: 0;
}

.button-success {
  background-color: $success-bg !important;
  border-color: $success-bg !important;
  color: white !important;
}

.button-danger {
  background-color: $error-bg !important;
  border-color: $error-bg !important;
  color: white !important;
}

:root .text-danger {
  color: $error-bg !important;
}

.k-level-1 {
  padding-left: 20px !important;
}

.border-bottom {
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
}

.k-card-footer {
  margin-top: -13px;
}

.k-card-header {
  color: $primary !important;
}

.setting-list {
  color: #424242 !important;
}

.drawer-bg {
  background-color: $drawer-bg !important;
}

.k-drawer-item {
  font-size: small !important;
}

.k-drawer-item.k-selected {
  border-top: 1px solid $menu-border !important;
  border-bottom: 1px solid $menu-border !important;
  color: $drawer-item-color !important;
  background: $drawer-item-bg !important;
}

.k-drawer-item:hover {
  color: $drawer-item-color !important;
  background: $drawer-item-bg !important;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background-color: #dbdbdb;
  border-radius: 1px;
  border: 1px solid #9d9d9d;
}

.main-theme {
  background-color: #f3f3f3;
  position: fixed;
  z-index: 1;
  width: 100%;
  padding: 10px;
}

.themeName {
  color: $body-text;
  font-size: 14px !important;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.theme-drawer-bg {
  background-color: #ffffff !important;
}

.col-right {
  text-align: right !important;
}

.col-center {
  text-align: center !important;
}

//** ---------------  Default CSS Classes  --------------------- **//
.drawer-login-name {
  font-size: 12px;
}

.main-header {
  font-size: 22px !important;
  font-weight: 700 !important;
}

.main-header-medium {
  font-size: 19px !important;
  font-weight: 650 !important;
}

.main-header-breadcrumb {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.progressbar-label {
  font-size: 13px !important;
  font-weight: 700 !important;
}

.progressbar-data {
  font-size: 12px !important;
}

.page-header {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: $secondary !important;
}

.page-header-breadcrumb {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.page-header-label {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $primary !important;
}

.grid-label {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $primary !important;
}

.grid-label-data {
  font-size: 13px !important;
  font-weight: 500 !important;
  text-align: center !important;
}

.page-header-data {
  font-size: 13px !important;
}

.k-card-body {
  padding: 7px 15px !important;
}

.custom-card-header {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: $secondary !important;
}

.card-header-secondary {
  @extend .custom-card-header;
}

.card-header-label {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: $primary !important;
}

.card-header-label-secondary {
  @extend .card-header-label;
  color: $secondary !important;
}

.card-header-data {
  font-size: 12px !important;
}

.card-header-data-secondary {
  @extend .card-header-data;
}

.location-header {
  font-size: 15px !important;
  font-weight: 700 !important;
  color: $secondary !important;
}

.location-label {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: $primary !important;
}

.location-data {
  font-size: 12px !important;
  color: $primary !important;
}

.list-header {
  font-size: 15px !important;
  font-weight: 700 !important;
  color: $primary !important;
}

.list-label {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.list-data {
  font-size: 11px !important;
}

.info-grid-small-header {
  vertical-align: middle !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 15px !important;
}

.info-grid-small-group-header {
  text-align: center !important;
  justify-content: center !important;
  font-weight: 700 !important;
}

.info-grid-small-datarow {
  font-size: 11px !important;
  line-height: 12px !important;
}

.info-grid-medium-header {
  vertical-align: middle !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  background-color: #f8f8f8 !important;
  color: $primary !important;
}

.info-grid-medium-group-header {
  justify-content: center !important;
  text-align: center !important;
  font-weight: 700 !important;
}

.info-grid-medium-datarow {
  font-size: 13px !important;
  line-height: 17px !important;
  background-color: #f4f4f4 !important;
}

.info-panel-label {
  font-size: 11px !important;
  font-weight: 700 !important;
  color: $primary !important;
}

.primary-label {
  font-size: 13px !important;
  line-height: 18px !important;
}

.info-panel-data {
  font-size: 11px !important;
  font-weight: 600 !important;
  position: relative !important;
}

.primary-data {
  font-size: 13px !important;
  color: $success;
  font-weight: 700 !important;
}

.border-bottom // Project List Type

.selected-icon {
  background-color: #f5f5f5 !important;
  color: $secondary !important;
  cursor: default !important;
  border: 1px solid $secondary !important;
}

.add-new {
  background-color: $secondary !important;
  color: #f5f5f5 !important;
}

.grid-view-search {
  border-radius: 25px !important;
}

.drawer-footer {
  position: fixed;
  bottom: 0;
  width: 250px;
  background-color: $primary;
  height: 50px;
}

.footer-button {
  width: 100%;
  height: 30px;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.footer-button:hover {
  color: $secondary;
}

.footer-license {
  background-color: #f2f2f2;
  color: $primary;
  text-align: left;
  white-space: nowrap;
  font-size: x-small;
  padding: 3px;
  height: 20px;
}

.app-settings {
  animation: spin 3s linear infinite !important;
  color: $secondary !important;
}

.more-menu {
  color: $secondary !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// ** Drawer Menu Icons
.menu-icon {
  font-family: "Font Awesome 6 Free" !important;
  font-size: 11px !important;
  width: 21px !important;
  height: 19px !important;
  border: 1px solid !important;
  border-radius: 5px !important;
}

.attachment-title {
  font-size: 16px !important;
  color: $secondary !important;
  font-weight: 500 !important;
}

.grid_outer {
  width: 100%;
  height: 100%;
  float: left;
}

.table_responsive {
  height: 100%;
}

//** ---------------  Customised kendo CSS variables  --------------------- **//

$breadcrumb-bg: #ffffff00 !important;
$breadcrumb-root-link-text: $primary !important;
$breadcrumb-link-hovered-bg: #ffffff00;
$breadcrumb-root-link-hovered-bg: #ffffff00;

$scrollview-arrow-icon-size: 2.5em !important;
$scrollview-navigation-default-opacity: 0.3 !important;
$scrollview-navigation-hover-opacity: 0.5 !important;

//** ---------------  Customised kendo CSS Classes  --------------------- **//
// textbox bg
.k-input-solid {
  background-color: #f9f9f9 !important;
}

// combobox datarow medium
.k-clear-value {
  height: 20px !important;
}

.k-list-scroller {
  font-size: 13px !important;
  line-height: 17px !important;
}

// combobox datarow select cursor
.k-table-list {
  cursor: pointer !important;
}

// Grid Pager
.k-grid-pager {
  font-size: 14px !important;
  color: $secondary !important;
}

$floating-label-font-size: 14px !important;

.k-input {
  font-size: 14px !important;
}

// info-panel-header
.k-expander-title {
  color: $secondary !important;
}

// switch
.k-switch-on .k-switch-track {
  background-color: $secondary !important;
  border-color: $secondary !important;
}

.k-switch-off .k-switch-thumb,
.k-switch-on .k-switch-thumb {
  border-color: #0000004d !important;
  color: #424242 !important;
  background-color: #ffffff !important;
}

.k-switch-sm .k-switch-label-on {
  left: 8px !important;
}

.k-switch-sm .k-switch-label-off {
  right: 10px !important;
}

.k-grid tfoot {
  text-align: right !important;
  color: $secondary !important;
}

.k-pager-wrap:focus {
  box-shadow: none !important;
}

.k-button:focus {
  box-shadow: none !important;
}

.k-chip-label {
  padding-bottom: 3px !important;
}

.k-chip-tn {
  font-size: 12px !important;
  padding: 2px 0px !important;
}

.k-chip-solid-info {
  border-color: #c5d9fa !important;
  color: #3e80ed !important;
  background-color: #f0f5fe !important;
}

// dialog box body
.k-content {
  background-color: $custom-card-bg !important;
  padding: 8px 15px !important;
}

.k-avatar-md {
  width: 40px !important;
  height: 40px !important;
  background-color: #ffff !important;
  border: 1px solid #ffff !important;
}

.k-toolbar {
  padding: 0px !important;
  border: none !important;
  color: #d5d3d3 !important;
  background-color: #f3f3f3 !important;
}

.k-map {
  height: 375px !important;
}


/* Dashboard Widget */
.widget_icon {
  color: $secondary !important;
  font-size: 25px;
}

.widget_label {
  font-size: 12px;
  color: $primary !important;
  font-weight: 700 !important;
}

kendo-chart-title {
  color: $secondary !important;
}

.agent_cus_wid_label {
  color: $secondary !important;
}

.chart_label {
  text-align: center;
  font-size: 14px;
  padding: 0px !important;
  color: $primary !important;
  font-weight: 700 !important;
}

.bg-red {
  width: 100% !important;
  padding: 13px !important;
}

.kendo_chart[_ngcontent-xor-c392] {
  height: 350px;
  padding: 3px !important;
}

/** Chandru for datepicker **/
// .k-calendar-infinite .k-calendar-weekdays {
//   width: 100% !important;
// }

.progressbar-bg {
  background-color: $secondary !important;
  color: #ffffff !important;
}

.option-icon {
  color: $primary !important;
}

.table-header th {
  color: $primary;
}

/** Department, JobTitle and Users **/
.department-active {
  border-left: 5px solid $primary !important;
}

.job-title-active {
  border-left: 5px solid $primary !important;
}

.user-active {
  border-left: 5px solid $primary !important;
}

.hide-header {
  display: none !important;
}

tr.gold,
tr.k-alt.gold {
  background-color: #7e7e7e !important;
  color: #ffffff;
}

.k-overlay {
  background-color: #0000001c !important;
}

$grid-selected-text: $secondary !important;

.k-upload .k-upload-files {
  max-height: 150px !important;
}

.\!k-color-success {
  color: $success !important;
}

.\!k-color-error {
  color: $error !important;
}

.platformLabel {
  float: right;
  color: $secondary;
  font-size: 15px;
  font-weight: normal;
}

.SampleLabel {
  color: $secondary;
  font-size: 13px;
  font-weight: 600;
}

.cardCenter {
  font-size: 16px;
  font-weight: 700;
  margin-top: 15px;
  text-align: center;
  margin: 30px;
}

.cardSpinner {
  color: $secondary !important;
}

.cardSpinnerBtn {
  margin-top: 5px !important;
}

.card-body-mb {
  width: 600px !important;
}

.pass-eye-mb {
  margin-top: 7px !important;
  margin-right: 130px;
}

.k-tabstrip-layout {
  height: 100% !important;
}

.disabledText {
  pointer-events: none;
  opacity: 0.6;
  // background-color: #80808029 !important;
}

/* Incentive */
.k-group-footer td,
.k-grouping-row td,
tbody .k-group-cell {
  color: #424242 !important;
  background-color: #f1f1f1 !important
}

.info-approval-card {
  height: 150px;
}

.card-header-section {
  padding: 10px;
  text-align: center;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.card-footer-section {
  padding: 10px;
  text-align: center;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.k-grid .k-column-title {
  white-space: normal !important;
}



.k-tabstrip {
  height: 100% !important;
}

@media (max-width:600px) {
  .card-body-mb {
    width: 385px !important;
  }
}


.metismenu {
  margin: 0;

  li {
    display: block;
    width: 100%;
  }

  .mm-collapse {
    display: none;

    &:not(.mm-show) {
      display: none;
    }

    &.mm-show {
      display: block;
    }
  }

  .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
  }
}

$font-weight-semibold: 600;
$white: #fff;
$gray-100: #f8f9fa;
$sidebar-bg: $primary;
$sidebar-menu-item-color: #fffc;
$sidebar-menu-sub-item-color: #fffc;
$sidebar-menu-item-icon-color: #fffc;
$sidebar-menu-item-hover-color: $white;
$sidebar-menu-item-active-color: $white;
$sidebar-width: 250px;
$sidebar-collapsed-width: 55px;
$sidebar-width-sm: 160px;
$header-height: 50px;
$black: #000;
$box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, .03);
$sidebar-dark-bg: #2a3042;

.sys-card {
  width: calc(100% - $sidebar-width) !important;
  height: calc(100% - $header-height) !important;
  display: flex;
  flex-direction: column;
  position: fixed;

  &-header {
    flex: 0 0 auto;
    background-color: #fcfcfc;
    color: $primary !important;
    position: relative;
    padding: 0px 16px !important;
    height: 3.5rem;
  }

  &-body {
    flex: 1 1 auto;
    position: relative;
    overflow-y: auto;
    overflow-x: auto;
    background-color: #fcfcfc !important;
    color: #424242;
    padding: 15px !important;
  }

  &-footer {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: flex-end;
    padding: 0.5rem;
    background-color: #f3f3f3;
  }
}

.vertical-collpsed {
  .sys-card {
    width: calc(100% - $sidebar-collapsed-width) !important;
    height: calc(100% - $header-height) !important;
    display: flex;
    flex-direction: column;
    position: fixed;

    &-header {
      flex: 0 0 auto;
      background-color: #fcfcfc;
      color: $primary !important;
      position: relative;
      padding: 0px 16px !important;
      height: 3.5rem;
    }

    &-body {
      flex: 1 1 auto;
      position: relative;
      overflow-y: auto;
      overflow-x: auto;
      background-color: #fcfcfc !important;
      color: #424242;
      padding: 15px !important;
    }

    &-footer {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      justify-content: flex-end;
      padding: 0.5rem;
      background-color: #f3f3f3;
    }
  }
}

.header-item {
  height: $header-height;
  box-shadow: none !important;
  color: $secondary !important;

  &:hover {
    color: $secondary !important;
  }
}

body {
  .navbar-brand-box {
    background: $sidebar-bg;
    // transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  }

  .logo-dark {
    margin-top: 15px;
    display: none;
  }

  .logo-light {
    display: block;
  }
}

.vertical-menu {
  width: $sidebar-width;
  z-index: 1001;
  background: $sidebar-bg;
  bottom: 50px;
  margin-top: 0;
  position: fixed;
  top: $header-height;
  box-shadow: $box-shadow;
  // transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  font-family: Poppins;
}

.main-content {
  margin-left: $sidebar-width;
  overflow: hidden;

  .content {
    padding: 0 15px 10px 15px;
    margin-top: $header-height;
  }
}

#sidebar-menu {
  padding: 0px 0 30px 0;
  background-color: $sidebar-bg;

  .mm-active {
    >.has-arrow {
      &:after {
        transform: rotate(-180deg);
      }
    }

    a.sigle-menu {
      padding: 7px 16px 7px 16px !important;
    }
  }

  .has-arrow {
    &:after {
      content: "\F0140";
      font-family: "Material Design Icons";
      display: block;
      float: right;
      transition: transform 0.2s;
      font-size: 1rem;
    }
  }

  ul {
    li {
      a {
        display: block;
        padding: 8px 16px;
        color: $sidebar-menu-item-color;
        position: relative;
        font-size: 12px;
        // transition: all 0.4s;

        img {
          display: inline-block;
          width: 22px;
          height: 22px;
        }

        &:hover {
          color: $sidebar-menu-item-hover-color;

        }
      }

      .badge {
        margin-top: 4px;
      }

      ul.sub-menu {
        padding: 0;

        li.mm-active {
          a.active {
            padding: 7px 24px 7px 36px;
          }
        }

        li {
          a {
            padding: 8px 24px 8px 36px;
            font-size: 12px;
            color: $sidebar-menu-sub-item-color;

            &:hover {
              color: $sidebar-menu-item-hover-color;
              color: $white !important;
              background: $secondary !important;
            }

          }



          ul.sub-menu {
            padding: 0;

            li {
              a {
                padding: 7px 24px 7px 58px;
                font-size: 12px;
              }
            }

            li.mm-active {
              a.active {
                padding: 6px 24px 6px 58px;
              }
            }
          }
        }
      }


    }
  }
}



.menu-title {
  padding: 12px 20px !important;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: $sidebar-menu-item-icon-color;
  font-weight: $font-weight-semibold;
}

.mm-active {
  color: $sidebar-menu-item-active-color !important;

  >a {
    color: $sidebar-menu-item-active-color !important;

    i {
      color: $sidebar-menu-item-active-color !important;
    }
  }

  .active {
    color: $sidebar-menu-item-active-color !important;
    border-top: 1px solid #f2f4fa !important;
    border-bottom: 1px solid #f2f4fa !important;
    color: $white !important;
    background: $secondary !important;
    // padding: 7px 24px 7px 36px !important;

    i {
      color: $sidebar-menu-item-active-color !important;
    }
  }

  >i {
    color: $sidebar-menu-item-active-color !important;
  }
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
  }

  .main-content {
    margin-left: 0 !important;
  }

  body.sidebar-enable {
    .vertical-menu {
      display: block;
    }
  }
}

// Enlarge menu
.vertical-collpsed li.mm-active,
a.is-parent.has-arrow.mm-active.mm-collapsed,
a.side-nav-link-a-ref.has-arrow.mm-active.mm-collapsed {
  border-top: 0px solid #f2f4fa !important;
  border-bottom: 0px solid #f2f4fa !important;
  color: #fff !important;
  background: $secondary !important;
}

a.is-parent.has-arrow:hover {
  color: #fff !important;
  background: $secondary !important;
}

.vertical-collpsed {
  .drawer-footer {
    display: none !important;
  }

  .main-content {
    margin-left: $sidebar-collapsed-width;
  }

  .navbar-brand-box {
    width: $sidebar-collapsed-width !important;
    // transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: block;
    }
  }

  // Side menu
  .vertical-menu {
    bottom: 0px !important;
    width: $sidebar-collapsed-width !important;
    z-index: 5;
    position: absolute !important;
    // transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    font-family: 'Poppins';
    // height: 115vh !important;

    .simplebar-mask,
    .simplebar-content-wrapper {
      overflow: visible !important;
    }

    .simplebar-scrollbar {
      display: none !important;
    }

    .simplebar-offset {
      bottom: 0 !important;
    }

    // Sidebar Menu
    #sidebar-menu {

      .menu-title,
      .badge,
      .collapse.in {
        display: none !important;
      }

      .nav.collapse {
        height: inherit !important;
      }

      .has-arrow {
        &:after {
          display: none;
        }
      }

      .has-arrow-show {
        &:after {
          content: "\F0140";
          font-family: "Material Design Icons";
          display: block;
          float: right;
          transition: transform 0.2s;
          font-size: 1rem;
          transform: rotate(-90deg);
        }
      }

      >ul {
        >li {
          position: relative;
          white-space: nowrap;

          >a {
            padding: 8px 8px !important;
            min-height: 37px;
            transition: none;

            >img {
              display: inline-block;
              margin-left: 5px;
              padding-bottom: 0.125rem;
              width: 22px;
              height: 22px;
            }

            &:hover,
            &:active,
            &:focus {
              color: $sidebar-menu-item-hover-color;
            }

            i {
              font-size: 1.45rem;
              margin-left: 4px;
            }

            span {
              display: none;
              padding-left: 25px;
            }
          }

          &:hover {
            >a {
              position: relative;
              width: calc(250px + #{$sidebar-collapsed-width});
              color: $white;
              background-color: $sidebar-bg;
              transition: none;

              i {
                color: $white;
              }

              span {
                display: inline;
              }
            }

            >ul {
              display: block;
              left: $sidebar-collapsed-width;
              position: absolute;
              width: 250px;
              height: auto !important;
              box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);

              ul {
                box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
              }

              a {
                box-shadow: none;
                padding: 7px 20px !important;
                position: relative;
                width: 250px;
                z-index: 6;
                color: $sidebar-menu-sub-item-color;

                &:hover {
                  color: $sidebar-menu-item-hover-color;
                }
              }
            }
          }

          >a.active {
            padding: 7px !important;
          }
        }

        ul {
          padding: 0px 0;
          z-index: 9999;
          display: none;
          background-color: $sidebar-bg;

          li {
            &:hover {
              >ul {
                display: block;
                left: 250px;
                height: auto !important;
                margin-top: -38px;
                position: absolute;
                width: 250px;
              }
            }

            >a {
              span.pull-right {
                position: absolute;
                right: 20px;
                top: 12px;
                transform: rotate(270deg);
              }
            }
          }

          li.active {
            a {
              color: $gray-100;
            }
          }
        }
      }
    }
  }
}

.rightbar-title {
  color: $primary !important;
}

.right-bar-toggle {
  background-color: $secondary !important;
  color: #fff !important;
}

//small
.grid-small-class {
  font-size: 11px !important;
  line-height: 15px !important;
  justify-content: center !important;
  // font-weight: 500 !important;
}

.grid-small-column-group {
  justify-content: center !important;
  font-size: 12px !important;
  vertical-align: middle !important;
  font-weight: 700 !important;
}

.grid-small-column {
  vertical-align: middle !important;
  font-size: 12px !important;
  font-weight: 700 !important;
}

//Medium
.grid-medium-class {
  font-size: 13px !important;
  line-height: 17px !important;
  justify-content: center !important;
  // font-weight: 700 !important;
}

.grid-medium-column-group {
  justify-content: center !important;
  font-size: 14px !important;
  vertical-align: middle !important;
  font-weight: 700 !important;
}

.grid-medium-column {
  vertical-align: middle !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.grid_height {
  width: 100% !important;
  height: 100% !important;
}

@import "node_modules/@progress/kendo-theme-default/dist/all.scss";