/* You can add global styles to this file, and also import other style files */

@import "tailwindcss/base";
@import "tailwindcss/components";

@import "tailwindcss/utilities";
@import "tailwindcss/utilities";

@import "tailwindcss/utilities";

// @import "./assets/scss/bootstrap.scss";
// @import "./assets/scss/app.scss";
// @import "./assets/scss/icons.scss";

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

ngx-dropzone {
  border: 2px dashed var(--bs-border-color) !important;
}

ngx-dropzone-preview {
  max-width: none !important;
  justify-content: normal !important;
  height: 80px !important;
  min-height: 80px !important;
  background-image: none !important;

  img {
    opacity: 1 !important;
  }

  ngx-dropzone-label {
    position: unset !important;
  }
}

.leave-request {
  width: 750px !important;
  height: 692px;
}

@media screen and (min-width: 300px) and (max-width: 770px) {

  .grids {
    display: block !important;
  }

  .k-dialog-wrapper .k-dialog {
    position: relative;
    height: 100% !important;
    width: 100% !important;
  }

  .leave-request {
    width: 100% !important;
    height: 100% !important;
  }

  .approver-margin {
    margin-top: 0 !important;
  }

  .employee {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;

    .col-span-5 {
      grid-column: span 2 / span 2 !important;
    }
  }

  .designation {
    display: block !important;
  }

  .designation-margin {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }
}